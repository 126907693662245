/*General*/
:root {
  --lighterTheme: hsl(210, 75.5%, 73.1%);
  --lightTheme: hsl(210, 75.5%, 58.1%);
  --theme: hsl(210, 75.5%, 43.1%);
  --darkTheme: hsl(210, 75.5%, 28.1%);
  --darkerTheme: hsl(210, 75.5%, 13.1%);

  --transparent: hsla(0, 100%, 100%, 0);
  --white: hsl(0, 100%, 100%);
  --black: hsl(0, 0%, 0%);
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Arial", sans-serif;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

.disable-scroll {
  overflow: hidden;
}

.green {
  color: green;
}

.red {
  color: red;
}

.disabled-button {
  opacity: 0.5;
}

/*Header*/
.header-container {
  position: relative;
  color: #fff;
  width: 100%;
  height: 85vh;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.home-image {
  height: 100vh;
  background-image: linear-gradient(hsla(0, 0%, 0%, 0.6), hsla(0, 0%, 0%, 0.6)), url("./images/iStock-1.jpg");
}

.schools-image {
  background-image: linear-gradient(hsla(0, 0%, 0%, 0.6), hsla(0, 0%, 0%, 0.6)), url("./images/iStock-6.jpg");
}

.substitutes-image {
  background-image: linear-gradient(hsla(0, 0%, 0%, 0.6), hsla(0, 0%, 0%, 0.6)), url("./images/iStock-4.jpg");
}

.about-image {
  background-image: linear-gradient(hsla(0, 0%, 0%, 0.6), hsla(0, 0%, 0%, 0.6)), url("./images/iStock-3.jpg");
}

.contact-image {
  background-image: linear-gradient(hsla(0, 0%, 0%, 0.6), hsla(0, 0%, 0%, 0.6)), url("./images/iStock-5.jpg");
}

#header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logo {
  height: 100%;
  width: min(250px, 70vw);
}

.logo-container {
  margin: 2rem 0 0 1.5rem;
}

#main-menu {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  height: 100%;
}

#main-menu li {
  margin: 2.5rem 1.5rem 0 1.5rem;
  font-size: 0.8rem;
  font-weight: 700;
}

#main-menu a {
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s;
  color: var(--white);
}

#main-menu a:hover {
  color: var(--theme);
  font-size: 1.02em;
}

.hide {
  display: none;
}

.main-page-container {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.main-page-text {
  margin: 0 auto;
  max-width: 60vw;
  text-align: center;
  color: white;
}

.main-page-text p {
  margin: 0.4rem 0;
}

.buttons-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.buttons-container a {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 700;
  margin: 0.5rem;
  padding: 1.1rem 2.5rem;
  border-radius: 0.1rem;
  text-shadow: 2px 2px 4px hsla(0, 0%, 0%, 0.3);
  box-shadow: 2px 2px 8px hsla(0, 0%, 0%, 0.2);
  text-decoration: none;
  transition: all 0.35s;
  background-color: var(--darkTheme);
  color: white;
}

.buttons-container a:hover {
  background-color: white;
  color: var(--darkTheme);
  box-shadow: 8px 8px 12px hsla(0, 0%, 0%, 0.2);
}

/*-menu-navigation*/
.menu-bar-icon {
  display: none;
  cursor: pointer;
}
/*Bodies*/
.text-container {
  margin: 8vh auto;
  padding: 0 2rem;
  max-width: 50rem;
}

.contact-info-box {
  padding: 2rem;
  background-color: var(--darkerTheme);
  color: var(--white);
  border-radius: 0.1rem;
}

.contact-info-box li {
  list-style-type: none
}

.contact-info-box h4 {
  margin: 0.75rem 0 0.2rem 0;
}

.subheading {
  margin: 0 0 1rem 0;
  color: var(--darkerTheme);
  font-size: 1.8rem;
}

.text-container p {
  color: rgb(50, 50, 50);
  line-height: 1.6rem;
  margin: 0 0 1.5rem 0;
}
/*Footer*/
#footer {
  color: rgba(225, 225, 225);
  width: 100%;
  background-color: rgba(50, 50, 50);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 25%;
  padding: 10vh 0;
  line-height: 1.4;
}

.icon-style {
  font-size: 1.8rem;
  padding-bottom: 2px;
}

/*Forms*/
.form-section {
  margin: auto;
  padding: 8vh 5rem;
  background-color: hsl(17, 18%, 90%);
}

.form {
  display: flex;
  flex-direction: column;
}

.form h2 {
  margin: 0 0 1rem 0;
}

.substitute-number-header {
  color: var(--darkerTheme);
  margin: 0.75rem 0;
  text-align: center;
}

.form input,
.form textArea {
  padding: 0.8rem;
  outline: none;
  background-color: var(--transparent);
  border: none;
  border-bottom: 0.1rem solid hsl(0, 0%, 60%);
  transition: all 0.3s;
}

.form input:focus,
.form textArea:focus {
  border-color: #05f7ff;
  background-color: hsla(0, 0%, 100%, 0.3);
}

.form input[type="file"] {
  padding: 0.8rem 0;
  background-color: var(--transparent);
  border: none;
}

.form input[type="radio"] {
  margin: 0 0 0 0.5rem;
}

.radio-container {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  font-size: 0.95rem;
}

.form label,
.form h2 {
  margin: 0.5rem 0.1rem 0.5rem;
  color: var(--darkerTheme);
}

.form button {
  font-size: 1rem;
  font-weight: 700;
  margin: 0.75rem 0 0 0;
  padding: 0.75rem 0.5rem;
  border: none;
  color: var(--white);
  transition: background-color 0.3s;
}

.form button:hover:enabled {
  opacity: 0.8;
}

.form button:active:enabled {
  opacity: 0.6;
}

.submit-button {
  background-color: var(--theme);
}

.form-icons-group {
  display: flex;
  flex-direction: row;
}

.form-icons {
  padding: 10px;
  color: white;
  align-self: center;
  cursor: pointer;
  margin: 0 0.25rem;
}

.form-icons:hover {
  opacity: 0.8;
}

.remove-icon {
  background-color: red;
}

.add-icon {
  background-color: green;
}

.form-group {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.form-object {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: flex-start;
}

.flex-shrink {
  flex-shrink: 2;
}

.divider {
  color: var(--darkTheme);
}

.submit-response-text {
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 980px) {
/*General*/
  #main-menu {
    position: fixed;
    top: 0;
    right: 0;
    padding: 4rem 0;
    width: max(45%, 250px);
    height: 100%;
    z-index: 5;
    flex-direction: column;
    background-color: var(--theme);
  }

  #main-menu li {
    margin: 0;
    padding: 1.25rem;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  #main-menu a:hover {
    opacity: 0.5;
    color: var(--white);
    font-size: 1em;
  }

  .menu-active {
    transition: transform 0.5s;
    transform: translateX(0%);
  }

  .menu-inactive {
    transition: transform 0.5s;
    transform: translateX(100%);
  }

  .main-page-container {
    top: 45%;
  }

  .main-page-text {
    max-width: 80vw;
  }
/*-menu-navigation*/
  .menu-bar-icon {
    display: block;
    position: absolute;
    top: min(2rem, 8vw);
    right: min(1rem, 4vw);
    padding: 5px;
    font-size: min(1.5rem, 15vw);
    z-index: 10;
  }

  .menu-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.4);
  }

/*Bodies*/
  .text-container {
    margin: 4vh auto;
    padding: 0 1.25rem;
  }

  .subheading {
    font-size: 1.6rem;
  }

/*Footer*/
  .info-container {
    flex-basis: 50%;
    padding: 5vh 0;
  }

/*Forms*/
  .form-section {
    padding: 8vh 2.5rem;
  }
}

@media (max-width: 500px) {

  .main-page-container {
    top: 40%;
  }

  .main-page-text {
    max-width: 95vw;
  }

  .main-page-text h1 {
    font-size: 1.7rem;
  }

  .main-page-text p {
    font-size: 0.95rem;
  }

  .buttons-container {
    flex-direction: column;
  }

  /*Bodies*/
  .subheading {
    font-size: 1.5rem;
  }

  /*Footer*/
  .info-container {
    flex-basis: 100%;
    padding: 5vh 0;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .form-icons {
    margin: 0.5rem 0.25rem;
    flex-basis: 100%;
  }
/*Forms*/
  .form-section {
    padding: 8vh 1rem;
  }
}
